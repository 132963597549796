import React from "react";
import CountryPage from "../../components/country-page";

const ANDROID_BTN_CONFIG = {
  os: "android",
  href:
    "https://play.google.com/store/apps/details?id=com.letiagoalves.radiotuga.brasil",
  target: "_blank",
  text: "Download para Android",
  bgColor: "#F0D10A",
  fgColor: "#000000",
};

export default () => (
  <CountryPage
    countryCode="br"
    appName="Rádio Brasil"
    headline1={
      "Faz download gratuitamente da aplicação Rádio Brasil e ouve mais de 200 emissoras do Brasil!"
    }
    headline2={
      "A Rádio Brasil foi desenvolvida para levar a rádio Brasileira a todo o mundo"
    }
    androidBtnConfig={ANDROID_BTN_CONFIG}
  />
);
